import React from "react"
import Index from "@pages/index.js";
import { graphql } from 'gatsby';

const IndexJa = (props) => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang} queryData={props.data} pageContext={props.pageContext}/></>
  )
}
export const query = graphql`
  query {
    allArticle: allWpArticle(
      limit: 3
      filter: {articleTag: {nodes: {elemMatch: {slug: {eq: "ja"}}}}}
      sort: {fields: [date], order: DESC}) {
      edges {
        node {
          slug
          title
          date(formatString: "MMMM DD, YYYY")
          modified(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allNews: allWpNews(
      limit: 3
      filter: {newsTag: {nodes: {elemMatch: {slug: {eq: "ja"}}}}}
      sort: {fields: [date], order: DESC}) {
      edges {
        node {
          slug
          title
          date(formatString: "MMMM DD, YYYY")
          modified(formatString: "MMMM DD, YYYY") 
        }
      }
    }
  }
`;
export default IndexJa
